import { Box, Divider, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { ALARM_TYPE, MENU_PAGE_TITLE } from '../../../components/settings/SettingConstEnum';
import { IRealtimeMonitoringAlarmFilter, useStores } from '../../../models';
import { CallApiToStore } from '../../Home';
import SearchAreaButtonAndSeverityChip from '../Common/SearchAreaButtonAndSeverityChip';
import RealtimeMonitoringSearchArea from '../Common/RealtimeMonitoringSearchArea';
import { RealtimeMonitoringTable } from '../Common/RealtimeMonitoringTable';
import CDialog from '../../../components/CDialog';
import AlarmSetup from '../Common/AlarmSetup';
import AlarmSound from '../Common/AlarmSound';
import { Client } from '@stomp/stompjs';
import AiAlarmsDetail from '../../oneview/ai_alarms/AiAlarmsDetail';

const DisabledPersonAlarmScreen = observer(function DisabledPersonAlarmScreen() {
  const rootStore = useStores();
  const { realtimeAlarmStore, loadingStore, commonStore } = rootStore;

  const probableCauseList = ['Loss of Disabled Person Signal'];
  const monitorType = 'disabled-person-alarm'
  const initialSearchData = {
    eventTimeSt: moment().format('YYYY-MM-DD 00:00:00'),
    eventTimeEd: moment().format('YYYY-MM-DD 23:59:59'),
    probableCauseList,
  };
  const [searchData, setSearchData] = useState<IRealtimeMonitoringAlarmFilter>(initialSearchData);
  const [openSearchArea, setOpenSearchArea] = useState<boolean>(false);
  const [openAlarmSetup, setOpenAlarmSetup] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const MENU_TITLE = MENU_PAGE_TITLE.realtimeMonitoring.title;
  const PAGE_TITLE = MENU_PAGE_TITLE.realtimeMonitoring.disabledPersonAlarm;
  

  const { REACT_APP_WS_PREFIX, REACT_APP_WS_URL, REACT_APP_API_CONTEXT_PATH } = process.env;
  const baseUrl = REACT_APP_WS_URL || commonStore.windowLocationOrigin.split('//')[1];

  var ws = new Client({
    brokerURL: `${REACT_APP_WS_PREFIX}${baseUrl}/${REACT_APP_API_CONTEXT_PATH}/ws/caims`,
    debug: (msg) => {},
  });

  const handleDialogClose = () => setIsDialogOpen(false);

  function changeDialogDetail() {
    setIsDialogOpen(true);
  }

  useEffect(() => {
    return () => {
      ws.deactivate();
    };
  }, []);

  useEffect(()=>{
    const updateEventInterval = setInterval( realtimeAlarmStore.updateEventDuration,  60000)
    return () => clearInterval(updateEventInterval)
  }, [])
  

  const onSearch = (data: IRealtimeMonitoringAlarmFilter) => {
    setSearchData(data);
  };

  const getTableData = async () => {
    await CallApiToStore(
      realtimeAlarmStore.getRealtimeMonitoringAlarm(searchData as IRealtimeMonitoringAlarmFilter),
      'page',
      loadingStore,
    );
  };

  const alarmConfigRequest = async()=>{
    await realtimeAlarmStore.getAlarmSetup(monitorType)
    await realtimeAlarmStore.getAllAlarmSounds(monitorType)
    await realtimeAlarmStore.realtimeAlarmSocket(probableCauseList, ws);
  }

  useEffect(() => {
    alarmConfigRequest()
    getTableData();

    const today = moment().startOf('day'); // today 00:00:00
    const endOfDay = today.add(1, 'day').add(1, 'millisecond');
    const endOfDayMs = endOfDay.valueOf();
    const currentTime = moment().valueOf();
    const timerValue = endOfDayMs - currentTime;

    setTimeout(() => {
      getTableData();
    }, timerValue);
  }, []);

  const onDownload = (data: IRealtimeMonitoringAlarmFilter) => {
    loadingStore.setMessage('다운로드 중입니다..');
    CallApiToStore(
      realtimeAlarmStore.downloadExcel({ ...data, probableCauseList }),
      'api',
      loadingStore,
    );
  };

  return (
    <>
      <Grid container spacing={0} className={'header'} alignItems={'end'}>
        <Grid item xs={6}>
          <HeaderBreadcrumbs
            heading={PAGE_TITLE}
            links={[
              { name: MENU_TITLE },
              {
                name: PAGE_TITLE,
              },
            ]}
          ></HeaderBreadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <SearchAreaButtonAndSeverityChip searchData={searchData} setOpenSearchArea={setOpenSearchArea} setOpenAlarmSetup={setOpenAlarmSetup}/>
        </Grid>
      </Grid>
      {!openSearchArea && <Divider />}

      <RealtimeMonitoringSearchArea
        onSearch={onSearch}
        openSearchArea={openSearchArea}
        onDownload={onDownload}
      />
      <CDialog
        maxWidth={'md'}
        isDialogOpen={openAlarmSetup}
        dialogTitle={"알람 사운드 설정"}
        handleDialogClose={() => {
          setOpenAlarmSetup(false);
        }}
        keyId={'alarmSetupPopup'}
        minHeight={'500px'}
      >
        <AlarmSetup handleCloseDialog={() => {
          setOpenAlarmSetup(false);
        }} monitorType={monitorType} />
      </CDialog>
      <CDialog
        aria-label={'알람 내용 상세보기'}
        maxWidth={'md'}
        isDialogOpen={isDialogOpen}
        dialogTitle={'알람 내용 상세보기'}
        handleDialogClose={handleDialogClose}
        keyId={'VocAlarmHistoryDialog'}
        enableScrollbar={false}
        fullWidth={true}
      >
         <AiAlarmsDetail handleCloseDialog={handleDialogClose} removeOnTerminate={false} />
      </CDialog>
      <Box sx={{ width: '100%' }}>
        <RealtimeMonitoringTable searchData={searchData} alarmType={ALARM_TYPE.DISABLED_PERSON_ALARM} changeDialogDetail={changeDialogDetail}/>
      </Box>
      <AlarmSound/> 
    </>
  );
});

export default DisabledPersonAlarmScreen;
