import { ApiResponse } from 'apisauce';
import { IResponseSnapshot } from '../../models';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IAlarmFilter, IAlarmSnapshot } from '../../models/alarm/Alarm';
import { IPaginationSnapshot } from '../../models/pagination/Pagination';
import { TGetAlarmResult, TGetAlarmsResult as TGetAlarmsResult } from './AlarmTypes';
import moment from 'moment';

/**
 * # Alarm Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new AlarmApi();
 *
 * ```
 */
type TGetsParams = {
  size: number;
  page: number;
} & IAlarmFilter;

export class AlarmApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    pagination: IPaginationSnapshot,
    alarmFilter?: IAlarmFilter,
  ): Promise<TGetAlarmsResult> {
    try {
      const url = '/event/history';
      let params: TGetsParams = {
        size: pagination.size,
        page: pagination.page,
      };

      params = { ...params, ...alarmFilter };

      (params.eventTimeSt) && (params.eventTimeSt = moment(params.eventTimeSt).format('YYYY-MM-DD 00:00:00'));
      (params.eventTimeEd) && (params.eventTimeEd = moment(params.eventTimeEd).format('YYYY-MM-DD 23:59:59'));

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        alarms: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async allAlarmGets(
      pagination: IPaginationSnapshot,
      alarmFilter?: IAlarmFilter,
  ): Promise<TGetAlarmsResult>{
    try {
      const url = '/event/all';
      let params: TGetsParams = {
        size: pagination.size,
        page: pagination.page,
      };

      params = { ...params, ...alarmFilter };

      (params.eventTimeSt) && (params.eventTimeSt = moment(params.eventTimeSt).format('YYYY-MM-DD 00:00:00'));
      (params.eventTimeEd) && (params.eventTimeEd = moment(params.eventTimeEd).format('YYYY-MM-DD 23:59:59'));

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        alarms: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async download(alarmFilter?: IAlarmFilter, targetType?: string): Promise<any> {
    // Request URI		/v1/excel/service/{service}/target/{target}
    let params: string = '';
    Object.entries({ ...alarmFilter }).map(([key, value]) => {
      if(value){
        if(key === 'eventTimeSt'){
          value = moment(value.toString()).format('YYYY-MM-DD 00:00:00');
        }else if(key === 'eventTimeEd'){
          value = moment(value.toString()).format('YYYY-MM-DD 23:59:59');
        }
        params += key + '=' + value + '&';
      }
    });

    const service = 'event';
    const target = targetType ||  'history';

    const url: string = `/excel/service/${service}/target/${target}?` + params.substring(0, params.length-1);
    return this.api.download(url, '');//'ChannelAI-Alarm-History.xls');
  }

  async get(eventId: string): Promise<TGetAlarmResult> {
    try {
      const url: string = `/event/${eventId}/history`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          alarm: response.data.data,
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        alarm: {} as IAlarmSnapshot,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
