import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EquipmentApi } from '../../services/equipment/Equipment';
import {
  TCheckDeleteEquipmentResult,
  TCheckHaTypeResult,
  TDeleteEquipmentResult,
  TDuplicateEquipmentName,
  TGetEquipmentResult,
  TGetEquipmentsResult,
  TPostEquipmentResult,
  TUpdateEquipmentResult, TUploadResult,
} from '../../services/equipment/EquipmentTypes';
import { createEquipment, Equipment, IEquipment } from '../equipment/Equipment';
import { createHaTypeCheckResult, IHaTypeCheckResult } from '../equipment/HaTypeCheckResult';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination, IPagination, IPaginationSnapshot, Pagination } from '../pagination/Pagination';
import { IEquipmentSnapshot } from './../equipment/Equipment';
import { withRootStore } from './../extensions/with-root-store';
import { createUploadResult, IUploadResult } from "../equipment/UploadResult";
import { toInteger } from 'lodash';
import { RootStoreModel } from '../root-store/root-store';
import { selectOptions } from '../../components/forms/CSelect';
import { toJS } from 'mobx';
import { CC } from '../../commonCodes/commonCodes';

export const EquipmentStore = types
  .model('EquipmentStore')
  .props({
    equipments: types.optional(types.array(Equipment), []),
    modalEquipments: types.optional(types.array(Equipment), []),
    selectedEquipmentFM: types.optional(types.maybeNull(Equipment), null),
    selectedEquipment: createEquipment(),
    selectedMainEquipment: createEquipment(),
    selectedPairEquipment: createEquipment(),
    deleteEquipmentList: types.optional(types.array(types.number), []),
    isSuccess: types.optional(types.boolean, false),
    pagination: createPagination(),
    modalPagination: createPagination(),
    errorMessage: types.optional(types.string, ''),
    checkEquipSidList: types.optional(types.array(types.number), []),
    duplicateHost: types.optional(types.boolean, false),
    checkResult: types.optional(types.array(types.string), []),
    checkHaResult: createHaTypeCheckResult(),
    uploadResult: createUploadResult(),
    racks: types.optional(types.array(Equipment), []),
    selectedRack: types.optional(types.maybeNull(Equipment), null)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    getUpTimeStr: (upTime: number) => {
      let day = toInteger(upTime / (60 * 60 * 24));
      let hour = toInteger((upTime % (60 * 60 * 24)) / (60 * 60));
      let min = toInteger((upTime % (60 * 60)) / (60));
      let sec = toInteger(upTime % (60));

      return `${day}일 ${hour}시간 ${min}분 ${sec}초`;
    }
  }))
  .actions((self) => ({
    setSelectedEquipment: (equipment: IEquipmentSnapshot) => {
      if (equipment?.upTime) {
        let upTime = equipment.upTime;
        let hourSec: number = 24 * 60 * 60;
        let minSec: number = 60 * 60;
        let secSec: number = 60;
        let msSec: number = 1;

        // if(equipment?.equipType?.code == CC.EQUIP_TYPE.L3)
        // {
        //   hourSec = hourSec * 1000;
        //   minSec = minSec * 1000;
        //   secSec = secSec * 1000;
        //   msSec = 1000;
        // }

        let day = toInteger(upTime / hourSec);
        let hour = toInteger((upTime % hourSec) / minSec);
        let min = toInteger((upTime % minSec) / secSec);
        let sec = toInteger(upTime % secSec) / msSec;
        equipment.upTimeStr = `${day}일 ${hour}시간 ${min}분 ${sec}초`;
      }

      const rackSid = equipment.rackSid;
      if(self.rootStore.commonStore.rackCodeList && rackSid)
      {
        self.rootStore.commonStore.rackCodeList.forEach(item => {
            if(item.code === rackSid)
            {
              equipment.rack = toJS(item);
              return;
            }
        });
      }

      self.selectedEquipment = equipment as IEquipment;
    },
    setSelectedEquipmentFM: (equipment: IEquipmentSnapshot | null) => {
      if (equipment?.upTime) {
        equipment.upTimeStr = self.getUpTimeStr(equipment.upTime);
      }
      self.selectedEquipmentFM = equipment as IEquipment | null;
    },
    setSelectedMainEquipment: (equipment: IEquipmentSnapshot) => {
      if (equipment?.upTime) {
        equipment.upTimeStr = self.getUpTimeStr(equipment.upTime);
      }
      self.selectedMainEquipment = equipment as IEquipment;
    },
    setSelectedPairEquipment: (equipment: IEquipmentSnapshot) => {
      if (equipment?.upTime) {
        equipment.upTimeStr = self.getUpTimeStr(equipment.upTime);
      }
      self.selectedPairEquipment = equipment as IEquipment;
    },
    setDeleteEquipmentList: (deleteEquipmentSid: number[]) => {
      self.deleteEquipmentList.replace(deleteEquipmentSid);
    },
    appendDeleteEquipmentList: (deleteEquipmentSid: number) => {
      self.deleteEquipmentList.push(deleteEquipmentSid);
    },
    setEquipments: (equipments: IEquipmentSnapshot[]) => {
      self.equipments.clear();
      equipments.map((equipment, index) => {
        if (equipment?.upTime) {
          equipment.upTimeStr = self.getUpTimeStr(equipment.upTime);
        }
        self.equipments.push(equipment);
      });
      // self.equipments.replace(equipments as IEquipment[]);
    },
    setModalEquipments: (modalEquipments: IEquipmentSnapshot[]) => {
      self.modalEquipments.clear();
      modalEquipments.map((equipment, index) => {
        if (equipment?.upTime) {
          equipment.upTimeStr = self.getUpTimeStr(equipment.upTime);
        }
        self.modalEquipments.push(equipment);
      });
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setModalPagination: (modalPagination: IPaginationSnapshot) => {
      self.modalPagination = modalPagination as IPagination;
    },
    setDuplicateHost: (bool: boolean) => {
      self.duplicateHost = bool;
    },
    appendCheckEquipSidList: (equipSid: number) => {
      self.checkEquipSidList.push(equipSid);
    },
    replaceCheckEquipSidList: (equipSidList: number[]) => {
      self.checkEquipSidList.replace(equipSidList);
    },
    setCheckResult: (checkResult: string[]) => {
      self.checkResult.replace(checkResult);
    },
    setHaTypeCheckResult: (checkHaResult: IHaTypeCheckResult) => {
      self.checkHaResult = checkHaResult as IHaTypeCheckResult;
    },
    setUploadResult: (uploadResult: IUploadResult) => {
      self.uploadResult = uploadResult as IUploadResult;
    },
    setErrorMessage: (errorMessage: string) => {
      self.errorMessage = errorMessage;
    },
    eixstEquipReplace: (existEquipments: IEquipmentSnapshot[]) => {
      let replaceEquipments: IEquipmentSnapshot[] = [];
      self.equipments.forEach((equipment: IEquipmentSnapshot, index: number) => {
        let isExist: boolean = false;
        existEquipments.forEach((existEquipment: IEquipmentSnapshot, existLoopIndex: number) => {
          if (existEquipment.equipSid === equipment.equipSid) {
            isExist = true;
          }
        });
        if (!isExist) {
          replaceEquipments.push(equipment);
        }
      });
      self.equipments.replace(replaceEquipments as IEquipment[]);
    },
    setRacks: (equipments: IEquipmentSnapshot[]) => {
      self.racks.clear();
      self.racks.replace(equipments as IEquipment[]);
    },
    setSelectedRack: (equipment: IEquipmentSnapshot | null) => {
     self.selectedRack = equipment as IEquipment | null
    }
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00130
     * 전체 장비 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=610397168
     * @param equipmentFilter
     * @returns
     */
    gets: async (equipmentFilter?: TEquipmentFliter) => {
      self.setEquipments([]);
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        let result: TGetEquipmentsResult;

        if (equipmentFilter) {
          result = await equipmentApi.gets(self.pagination, equipmentFilter);
        } else {
          result = await equipmentApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          self.setEquipments(result.equipments ? result.equipments : []);

          if (!result.pagination) {
            return;
          }

          self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00130
     * 전체 장비 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=610397168
     * @param equipmentFilter
     * @returns
     */
    modalGets: async (equipmentFilter?: TEquipmentFliter) => {
      self.setModalEquipments([]);
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        let result: TGetEquipmentsResult;

        if (equipmentFilter) {
          result = await equipmentApi.gets(self.modalPagination, equipmentFilter);
        } else {
          result = await equipmentApi.gets(self.modalPagination);
        }

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.setModalEquipments(result.equipments ? result.equipments : []);

          if (!result.pagination) {
            return;
          }
          self.setModalPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00246
     * 전체 장비 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=979341252
     * @param equipmentFilter
     * @returns
     */
    chLineupGets: async (equipmentFilter?: TEquipmentFliter) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        let result: TGetEquipmentsResult;

        if (equipmentFilter) {
          result = await equipmentApi.getsChLineup(self.modalPagination, equipmentFilter);
        } else {
          result = await equipmentApi.getsChLineup(self.modalPagination);
        }

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.setModalEquipments(result.equipments ? result.equipments : []);

          if (!result.pagination) {
            return;
          }
          self.setModalPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00131
     * 해당 장비의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2109751758
     * @param equipSid
     * @returns
     */
    get: async (equipSid: number) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TGetEquipmentResult = await equipmentApi.get(equipSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.equipment) {
            return;
          }

          self.selectedEquipment.resetEquipment();

          if (result.equipment) {
            self.setSelectedEquipment(result.equipment);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00131
     * 해당 장비의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2109751758
     * @param equipSid
     * @returns
     */
    mainGet: async (equipSid: number) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TGetEquipmentResult = await equipmentApi.get(equipSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.equipment) {
            return;
          }

          self.selectedMainEquipment.resetEquipment();

          if (result.equipment) {
            self.setSelectedMainEquipment(result.equipment);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    pairGet: async (equipSid: number) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TGetEquipmentResult = await equipmentApi.get(equipSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.equipment) {
            return;
          }

          self.selectedPairEquipment.resetEquipment();

          if (result.equipment) {
            self.setSelectedPairEquipment(result.equipment);
          }
          self.setSelectedPairEquipment(result.equipment);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00132
     * 새로운 장비를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1159795797
     * @param equiment
     */
    post: async (equiment: IEquipmentSnapshot) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TPostEquipmentResult = await equipmentApi.post(equiment);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.selectedEquipment.resetEquipment();

          if (result.equipment) {
            self.setSelectedEquipment(result.equipment);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00133
     * 해당 장비의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1787617019
     * @param equipment
     */
    put: async (equipment: IEquipment) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TUpdateEquipmentResult = await equipmentApi.put(equipment);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.selectedEquipment.resetEquipment();
          if (result.equipment) {
            self.setSelectedEquipment(result.equipment);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00134
     * 해당 장비를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1995563321
     * @param equipSid
     */
    delete: async (equipSid: number) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TDeleteEquipmentResult = await equipmentApi.delete(equipSid);

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00135
     * 선택한 장비목록을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1650217314
     */
    deletes: async () => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TDeleteEquipmentResult = await equipmentApi.deletes(self.deleteEquipmentList);

        self.rootStore.responseStore.getResponseResult(result);
        self.setDeleteEquipmentList([]);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    checkDuplicatedEquipmentName: async (hostNm: string) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TDuplicateEquipmentName = await equipmentApi.checkDuplicatedEquipmentName(
          hostNm,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.result !== undefined) {
            self.setDuplicateHost(result.result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00245
     * 특정 장비를 삭제 할 수 있는지 확인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1805737500
     */
    checkDeleteEquipment: async () => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TCheckDeleteEquipmentResult = await equipmentApi.checkDeleteEquipment(
          self.checkEquipSidList,
        );
        self.setCheckResult([]);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.checkResult) {
            self.setCheckResult(result.checkResult);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 대표장비와 멤버장비간 HA Type을 확인한다.
     */
    checkHaType: async (equiment: IEquipmentSnapshot) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TCheckHaTypeResult = await equipmentApi.checkHaType(equiment);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.checkHaResult.resetHaTypeCheckResult();
          if (result.checkHaResult) {
            self.setHaTypeCheckResult(result.checkHaResult);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * 장비 템플릿 다운로드
     */
    downloadTemplate: async () => {
      const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
      await equipmentApi.downloadTemplate();
    },
    /**
     * 장비 다운로드
     */
    downloadExcel: async (equiment: IEquipmentFliter) => {
      const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
      await equipmentApi.downloadExcel(equiment);
    },
    /**
     * 장비 업로드
     */
    uploadEquipment: async (files: File[]) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        if (files) {
          let result: TUploadResult = await equipmentApi.upload(files);
          if (result.uploadResult) {
            self.setUploadResult(result.uploadResult);
          }
        }
      }
      catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
        self.setErrorMessage(e.errorMessage)
      }

    },

    getRacks: async (sids: number[]) => {
      self.setEquipments([]);
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        let result: TGetEquipmentsResult;

        result = await equipmentApi.getEquipmentRacks(sids);

        if (
          self.rootStore.responseStore.getResponseResult(result)
        ) {
          self.setRacks(result.equipments ? result.equipments : []);

        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    getRackWithCbptaInfo: async (rackSid: number) => {
      try {
        const equipmentApi: EquipmentApi = new EquipmentApi(self.environment.api);
        let result: TGetEquipmentResult;

        result = await equipmentApi.getRackCpbtaInfo(rackSid);
        if (
          self.rootStore.responseStore.getResponseResult(result)
        ) {
          self.setSelectedRack(result.equipment ? result.equipment : null)
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    }
  }));

type TEquipmentStore = Instance<typeof EquipmentStore>;
type TEquipmentStoreSnapshot = SnapshotOut<typeof EquipmentStore>;

export interface IEquipmentStore extends TEquipmentStore { }
export type TEquipmentStoreKeys = keyof TEquipmentStoreSnapshot & string;
export interface IEquipmentStoreSnapshot extends TEquipmentStoreSnapshot { }
export const createEquipmentStore = () => types.optional(EquipmentStore, {} as TEquipmentStore);

export interface IEquipmentFliter {
  equipSid?: number;
  hostNm?: string;
  equipType?: number;
  equipRole?: number;
  trtResol?: number;
  repEquipSid?: number;
  repYn?: boolean;
  selectedEquipSidList?: number[];
  haType?: number;
  equipMngr?: string;
  equipLo?: string;
}

export type TEquipmentFliter = IEquipmentFliter;
