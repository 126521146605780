import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IAlarm, IAlarmFilter } from '..';
import { AlarmApi, TGetAlarmsResult } from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { TGetAlarmResult } from '../../services/alarm/AlarmTypes';
import { withRootStore } from '../extensions/with-root-store';
import { createAlarm, IAlarmSnapshot as IAlarmSnapshot } from '../alarm/Alarm';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';

export const AlarmStore = types
  .model('AlarmStore')
  .props({
    alarms: types.optional(types.array(createAlarm()), []),
    allAlarms: types.optional(types.array(createAlarm()), []),
    pagination: createPagination(),
    selectedAlarm: createAlarm(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setAlarms: (alarms: IAlarmSnapshot[]) => {
      self.alarms.replace(alarms as IAlarm[]);
    },
    setAllAlarms: (alarms: IAlarmSnapshot[]) => {
      self.allAlarms.replace(alarms as IAlarm[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedAlarm: (alarm: IAlarmSnapshot) => {
      self.selectedAlarm = alarm as IAlarm;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00036
     * 알람 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=693327378
     * @param alarmFilter
     */
    gets: async (alarmFilter?: IAlarmFilter) => {
      try {
        const alarmApi: AlarmApi = new AlarmApi(self.environment.api);
        const result: TGetAlarmsResult = await alarmApi.gets(self.pagination, alarmFilter);

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.alarms) {
            result.alarms.forEach((item: IAlarmSnapshot) => {
              if (item.eventTime) {
                item.eventTime = timestampToDate(Number.parseInt(item.eventTime));
              }
              if (item.originalEventTime) {
                item.originalEventTime = timestampToDate(Number.parseInt(item.originalEventTime));
              }
              if (item.acknowledgedTime) {
                item.acknowledgedTime = timestampToDate(Number.parseInt(item.acknowledgedTime));
              }
              if (item.clearanceTime) {
                item.clearanceTime = timestampToDate(Number.parseInt(item.clearanceTime));
              }
            });
            self.setAlarms(result.alarms);
            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getAllAlarms: async (alarmFilter?: IAlarmFilter) => {
      try {
        const alarmApi: AlarmApi = new AlarmApi(self.environment.api);
        const result: TGetAlarmsResult = await alarmApi.allAlarmGets(self.pagination, alarmFilter);

        if (
            self.rootStore.responseStore.getResponseResult(result) &&
            result.pagination &&
            result.pagination.page === self.pagination.page
        ) {
          if (result.alarms) {
            result.alarms.forEach((item: IAlarmSnapshot) => {
              if (item.eventTime) {
                item.eventTime = timestampToDate(Number.parseInt(item.eventTime));
              }
              if (item.originalEventTime) {
                item.originalEventTime = timestampToDate(Number.parseInt(item.originalEventTime));
              }
              if (item.acknowledgedTime) {
                item.acknowledgedTime = timestampToDate(Number.parseInt(item.acknowledgedTime));
              }
              if (item.clearanceTime) {
                item.clearanceTime = timestampToDate(Number.parseInt(item.clearanceTime));
              }
            });
            self.setAllAlarms(result.alarms);
            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    download: async (alarmFilter?: IAlarmFilter, targetType?: string) => {
      const alarmApi: AlarmApi = new AlarmApi(self.environment.api);
      await alarmApi.download(alarmFilter, targetType);
    },

    /**
     * INF_CAIMS_00037
     * 해당 알람 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=330769538
     * @param eventId
     */
    get: async (eventId: string) => {
      try {
        const alarmApi: AlarmApi = new AlarmApi(self.environment.api);
        const result: TGetAlarmResult = await alarmApi.get(eventId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            if (result.alarm) {
              if (result.alarm.eventTime) {
                result.alarm.eventTime = timestampToDate(Number.parseInt(result.alarm.eventTime));
              }
              if (result.alarm.originalEventTime) {
                result.alarm.originalEventTime = timestampToDate(Number.parseInt(result.alarm.originalEventTime));
              }
              if (result.alarm.acknowledgedTime) {
                result.alarm.acknowledgedTime = timestampToDate(Number.parseInt(result.alarm.acknowledgedTime));
              }
              if (result.alarm.clearanceTime) {
                result.alarm.clearanceTime = timestampToDate(Number.parseInt(result.alarm.clearanceTime));
              }
              self.setSelectedAlarm(result.alarm);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TAlarmStore = Instance<typeof AlarmStore>;
type TAlarmStoreSnapshot = SnapshotOut<typeof AlarmStore>;

export interface IAlarmStore extends TAlarmStore { }
export type TAlarmStoreKeys = keyof TAlarmStoreSnapshot & string;
export interface IAlarmStoreSnapshot extends TAlarmStoreSnapshot { }
export const createAlarmStore = () => types.optional(AlarmStore, {} as TAlarmStore);
