export const timestampToDate = (dateTimeStampValue: number) => {
  if (!dateTimeStampValue) {
    return ``;
  }
  var day = new Date(dateTimeStampValue);
  day.setHours(day.getHours() + 9);
  return day.toISOString().replace('T', ' ').substring(0, 19);
};

export const strStampToDate = (str: string | null) => {
  if (!str) {
    return ``;
  }

  var day = new Date(Number.parseInt(str));
  day.setHours(day.getHours() + 9);
  return day.toISOString().replace('T', ' ').substring(0, 19);
};

export const dateToTimestamp = (dateValue: any) => {
  return new Date(dateValue).getTime();
};

export const startDateToElapsedTimeSec =  (date: string) => {
  let startDate = new Date(date)
  let currDate = new Date()
  let elapsedTimeMs = currDate - startDate

  return elapsedTimeMs/1000;
}

export const elapsedTimeSecToStartDate = (data: any) => {
  
  let day = new Date (new Date() - (data*1000))
  day.setHours(day.getHours() + 9);
  
 return day.toISOString().replace('T', ' ').substring(0, 19);
}