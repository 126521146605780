import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import CDialog from '../../../components/CDialog';
import { CTableHead } from '../../../components/CTableHead';
import TableRow from '../../../components/CTableRowBodyStyle';
import { Order } from '../../../components/CTableToolbar';
import { DIALOG_TYPE, ROWS_PER_PAGE } from '../../../components/settings/SettingConstEnum';
import { IAlarmSnapshot, useStores } from '../../../models';
import { CallApiToStore } from '../../Home';
import { headCells } from './AiAlarmDetailRelatedData';

interface AiAlarmDetailRelatedProps {
  getTableData: () => Promise<void>;
}

export const AiAlarmDetailRelated = observer(function AiAlarmDetailRelated({
  getTableData,
}: AiAlarmDetailRelatedProps) {
  const rootStore = useStores();

  const { realtimeAlarmStore, loadingStore } = rootStore;

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('eventId');
  const [selected, setSelected] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // 테이블 핸들링 함수
  // ----------------------------------------------------------------------

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    realtimeAlarmStore.relatedPagination.setProps({ page: newPage + 1 });
    getTableData();
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    realtimeAlarmStore.relatedPagination.setProps({
      page: 0 + 1,
      size: parseInt(event.target.value, 10),
    });
    getTableData();
  };

  useEffect(() => {
    realtimeAlarmStore.pagination.setProps({ page: 1, size: 50 });
  }, []);

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 0)', mt: 1.5 }}>
            <Table stickyHeader aria-labelledby="tableTitle" size={'medium'} className={"inner"}>
              <CTableHead
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={realtimeAlarmStore.relatedAlarms.length}
                headCells={headCells}
                removeCheckBox={false}
              />
              <TableBody>
                {realtimeAlarmStore.relatedAlarms.map((row, index) => {
                  const item = toJS(row);
                  const isItemSelected = isSelected(item.eventId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, item)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item.eventId + Math.random()}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      id={labelId}
                    >
                      {/*targetInstanceName*/}
                      <TableCell align="center">{item.targetInstanceName}</TableCell>
                      <TableCell align="center">{item.instanceName}</TableCell>
                      <TableCell align="center" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        {item.eventId}
                      </TableCell>
                      <TableCell align="center">{item.eventTime}</TableCell>
                      <TableCell align="center">{item.clearanceTime}</TableCell>
                      <TableCell align="center">{item.instanceType?.value}</TableCell>
                      <TableCell align="center">{item.eventType?.value}</TableCell>
                      <TableCell align="center">{item.perceivedSeverity?.value}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      >
                        {item.probableCause}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      >
                        {item.specificProblem}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      >
                        {item.message}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {realtimeAlarmStore.relatedAlarms.length === 0 && (
                  <TableRow
                    style={{
                      height: 53,
                    }}
                  >
                    <TableCell align="center" sx={{ fontWeight: 600 }} colSpan={7}>
                      알람 내역이 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={"inner"}
            rowsPerPageOptions={ROWS_PER_PAGE.Options}
            component="div"
            count={realtimeAlarmStore.relatedPagination.totalElements}
            rowsPerPage={realtimeAlarmStore.relatedPagination.size}
            page={realtimeAlarmStore.relatedPagination.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
});

export default AiAlarmDetailRelated;
