import { HeadCell } from '../../../components/CTableHead';

export const headCells: readonly HeadCell[] = [
  {
    id: 'targetInstanceName',
    numeric: true,
    disablePadding: true,
    label: '채널명',
  },
  {
    id: 'instanceName',
    numeric: false,
    disablePadding: true,
    label: '장비명',
  },
  {
    id: 'eventId',
    numeric: true,
    disablePadding: true,
    label: '이벤트 ID',
  },
  {
    id: 'eventTime',
    numeric: true,
    disablePadding: true,
    label: '이벤트 시간',
  },
  {
    id: 'clearanceTime',
    numeric: true,
    disablePadding: true,
    label: '종료 시간', 
  },
  {
    id: 'instanceType',
    numeric: false,
    disablePadding: true,
    label: '인스턴스 유형',
  },
  {
    id: 'eventType',
    numeric: false,
    disablePadding: true,
    label: '이벤트 유형',
  },
  {
    id: 'perceivedSeverity',
    numeric: false,
    disablePadding: true,
    label: '등급',
  },
  {
    id: 'probableCause',
    numeric: false,
    disablePadding: true,
    label: '발생원인',
    align: 'left',
  },
  {
    id: 'specificProblem',
    numeric: false,
    disablePadding: true,
    label: '상세원인',
    align: 'left',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: true,
    label: '메시지',
    align: 'left',
  },
];
