
// This source is generated by generator.
export const CC = {
    ACNT_STTS_CD : {
             "Waiting" : 10100
            ,"Rejected" : 10101
            ,"Active" : 10102
            ,"Inactive" : 10103
            ,"Suspended" : 10104
            ,"__list__" : [
                {
                     "code"     : 10100
                    ,"value"    : "승인대기"
                    ,"pcode"    : 101
                },
                {
                     "code"     : 10101
                    ,"value"    : "승인거절"
                    ,"pcode"    : 101
                },
                {
                     "code"     : 10102
                    ,"value"    : "정상"
                    ,"pcode"    : 101
                },
                {
                     "code"     : 10103
                    ,"value"    : "휴면"
                    ,"pcode"    : 101
                },
                {
                     "code"     : 10104
                    ,"value"    : "중지"
                    ,"pcode"    : 101
                }
            ]
        }
        ,LOGIN_PRVNT_STTS_CD : {
             "Normal" : 10200
            ,"AccountDisabledByBlackList" : 10201
            ,"PasswordByLocked" : 10202
            ,"__list__" : [
                {
                     "code"     : 10200
                    ,"value"    : "정상"
                    ,"pcode"    : 102
                },
                {
                     "code"     : 10201
                    ,"value"    : "블랙리스트 비활성화"
                    ,"pcode"    : 102
                },
                {
                     "code"     : 10202
                    ,"value"    : "비밀번호 연속 실패 잠김"
                    ,"pcode"    : 102
                }
            ]
        }
        ,SVC_STTS_CD : {
             "Normal" : 10300
            ,"ServiceExpired" : 10301
            ,"__list__" : [
                {
                     "code"     : 10300
                    ,"value"    : "정상"
                    ,"pcode"    : 103
                },
                {
                     "code"     : 10301
                    ,"value"    : "서비스 만료"
                    ,"pcode"    : 103
                }
            ]
        }
        ,MENU_TYPE_CD : {
             "Public" : 10400
            ,"Role" : 10401
            ,"__list__" : [
                {
                     "code"     : 10400
                    ,"value"    : "공통 메뉴"
                    ,"pcode"    : 104
                },
                {
                     "code"     : 10401
                    ,"value"    : "권한 메뉴"
                    ,"pcode"    : 104
                }
            ]
        }
        ,EQUIP_TYPE : {
             "Encoder" : 100101
            ,"IPAcoMux" : 100102
            ,"MUX" : 100103
            ,"Decoder" : 100104
            ,"L3" : 100105
            ,"Transcoder" : 100106
            ,"Sentry" : 100108
            ,"MSPP" : 100109
            ,"Psi" : 100110
            ,"Multiview" : 100111
            ,"QuetoneExtraction" : 100112
            ,"Thumbnail" : 100113
            ,"VideoStitching" : 100114
            ,"Server" : 100115
            ,"Verify" : 100116
            ,"ETC" : 100117
            ,"__list__" : [
                {
                     "code"     : 100101
                    ,"value"    : "Encoder"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100102
                    ,"value"    : "IP-ACO MUX"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100103
                    ,"value"    : "MUX"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100104
                    ,"value"    : "Decoder"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100105
                    ,"value"    : "L3"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100106
                    ,"value"    : "Transcoder"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100108
                    ,"value"    : "Sentry"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100109
                    ,"value"    : "전송 장비"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100110
                    ,"value"    : "PSI"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100111
                    ,"value"    : "Multiview"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100112
                    ,"value"    : "큐톤추출기"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100113
                    ,"value"    : "썸네일"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100114
                    ,"value"    : "비디오스티칭"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100115
                    ,"value"    : "서버"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100116
                    ,"value"    : "Verify"
                    ,"pcode"    : 1001
                },
                {
                     "code"     : 100117
                    ,"value"    : "기타"
                    ,"pcode"    : 1001
                }
            ]
        }
        ,CMPN : {
             "Maker" : 100201
            ,"Vendor" : 100202
            ,"MakerVendor" : 100203
            ,"__list__" : [
                {
                     "code"     : 100201
                    ,"value"    : "제조사 "
                    ,"pcode"    : 1002
                },
                {
                     "code"     : 100202
                    ,"value"    : "벤더"
                    ,"pcode"    : 1002
                },
                {
                     "code"     : 100203
                    ,"value"    : "제조사&벤더"
                    ,"pcode"    : 1002
                }
            ]
        }
        ,PRTL : {
             "HTTP" : 100301
            ,"HTTPS" : 100302
            ,"SSH" : 100303
            ,"Telenet" : 100304
            ,"SNMPv2" : 100305
            ,"SNMPv3" : 100306
            ,"SFTP" : 100307
            ,"__list__" : [
                {
                     "code"     : 100301
                    ,"value"    : "Http"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100302
                    ,"value"    : "Https"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100303
                    ,"value"    : "SSH"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100304
                    ,"value"    : "Telenet"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100305
                    ,"value"    : "SNMPv2"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100306
                    ,"value"    : "SNMPv3"
                    ,"pcode"    : 1003
                },
                {
                     "code"     : 100307
                    ,"value"    : "SFTP"
                    ,"pcode"    : 1003
                }
            ]
        }
        ,AUTH_METHOD : {
             "NoAuth" : 100401
            ,"IDPWD" : 100402
            ,"Keypair" : 100403
            ,"__list__" : [
                {
                     "code"     : 100401
                    ,"value"    : "인증 없음"
                    ,"pcode"    : 1004
                },
                {
                     "code"     : 100402
                    ,"value"    : "ID/PWD"
                    ,"pcode"    : 1004
                },
                {
                     "code"     : 100403
                    ,"value"    : "Keypair"
                    ,"pcode"    : 1004
                }
            ]
        }
        ,HA_TYPE : {
             "Standalone" : 100501
            ,"MainBackup" : 100502
            ,"ActiveStandby" : 100503
            ,"ActiveActive" : 100504
            ,"__list__" : [
                {
                     "code"     : 100501
                    ,"value"    : "Standalone"
                    ,"pcode"    : 1005
                },
                {
                     "code"     : 100502
                    ,"value"    : "Main-Backup"
                    ,"pcode"    : 1005
                },
                {
                     "code"     : 100503
                    ,"value"    : "Active-Standby"
                    ,"pcode"    : 1005
                },
                {
                     "code"     : 100504
                    ,"value"    : "Active-Active"
                    ,"pcode"    : 1005
                }
            ]
        }
        ,HA_STTUS : {
             "Standalone" : 100601
            ,"Main" : 100602
            ,"Backup" : 100603
            ,"Active" : 100604
            ,"Standby" : 100605
            ,"__list__" : [
                {
                     "code"     : 100601
                    ,"value"    : "Standalone"
                    ,"pcode"    : 1006
                },
                {
                     "code"     : 100602
                    ,"value"    : "Main"
                    ,"pcode"    : 1006
                },
                {
                     "code"     : 100603
                    ,"value"    : "Backup"
                    ,"pcode"    : 1006
                },
                {
                     "code"     : 100604
                    ,"value"    : "Active"
                    ,"pcode"    : 1006
                },
                {
                     "code"     : 100605
                    ,"value"    : "Standby"
                    ,"pcode"    : 1006
                }
            ]
        }
        ,HELTHY_STTUS : {
             "Alive" : 100701
            ,"Critical" : 100702
            ,"Major" : 100703
            ,"Minor" : 100704
            ,"Death" : 100705
            ,"__list__" : [
                {
                     "code"     : 100701
                    ,"value"    : "Alive"
                    ,"pcode"    : 1007
                },
                {
                     "code"     : 100702
                    ,"value"    : "Critical"
                    ,"pcode"    : 1007
                },
                {
                     "code"     : 100703
                    ,"value"    : "Major"
                    ,"pcode"    : 1007
                },
                {
                     "code"     : 100704
                    ,"value"    : "Minor"
                    ,"pcode"    : 1007
                },
                {
                     "code"     : 100705
                    ,"value"    : "Death"
                    ,"pcode"    : 1007
                }
            ]
        }
        ,PORT_TYPE : {
             "Logical" : 100801
            ,"Physical" : 100802
            ,"Bond" : 100803
            ,"Virtual" : 100804
            ,"__list__" : [
                {
                     "code"     : 100801
                    ,"value"    : "논리"
                    ,"pcode"    : 1008
                },
                {
                     "code"     : 100802
                    ,"value"    : "물리"
                    ,"pcode"    : 1008
                },
                {
                     "code"     : 100803
                    ,"value"    : "Bond"
                    ,"pcode"    : 1008
                },
                {
                     "code"     : 100804
                    ,"value"    : "가상"
                    ,"pcode"    : 1008
                }
            ]
        }
        ,PORT_STTUS : {
             "Unknown" : 100900
            ,"Up" : 100901
            ,"Down" : 100902
            ,"__list__" : [
                {
                     "code"     : 100900
                    ,"value"    : "알수없음"
                    ,"pcode"    : 1009
                },
                {
                     "code"     : 100901
                    ,"value"    : "Up"
                    ,"pcode"    : 1009
                },
                {
                     "code"     : 100902
                    ,"value"    : "Down"
                    ,"pcode"    : 1009
                }
            ]
        }
        ,EQUIP_OPRT_STTUS : {
             "Operation" : 101001
            ,"Test" : 101002
            ,"Idle" : 101003
            ,"Etc" : 101004
            ,"__list__" : [
                {
                     "code"     : 101001
                    ,"value"    : "운영"
                    ,"pcode"    : 1010
                },
                {
                     "code"     : 101002
                    ,"value"    : "테스트"
                    ,"pcode"    : 1010
                },
                {
                     "code"     : 101003
                    ,"value"    : "유휴"
                    ,"pcode"    : 1010
                },
                {
                     "code"     : 101004
                    ,"value"    : "기타"
                    ,"pcode"    : 1010
                }
            ]
        }
        ,TRT_RESOL : {
             "Hd" : 101101
            ,"Sd" : 101103
            ,"K4" : 101104
            ,"K8" : 101105
            ,"Audio" : 101106
            ,"__list__" : [
                {
                     "code"     : 101101
                    ,"value"    : "HD"
                    ,"pcode"    : 1011
                },
                {
                     "code"     : 101103
                    ,"value"    : "SD"
                    ,"pcode"    : 1011
                },
                {
                     "code"     : 101104
                    ,"value"    : "4K"
                    ,"pcode"    : 1011
                },
                {
                     "code"     : 101105
                    ,"value"    : "8K"
                    ,"pcode"    : 1011
                },
                {
                     "code"     : 101106
                    ,"value"    : "Audio"
                    ,"pcode"    : 1011
                }
            ]
        }
        ,EQUIP_ROLE : {
             "General" : 101201
            ,"In" : 101202
            ,"Out" : 101203
            ,"YeouidoLink" : 101204
            ,"DbsLink" : 101205
            ,"SdCentralizeLineSw" : 101206
            ,"__list__" : [
                {
                     "code"     : 101201
                    ,"value"    : "일반"
                    ,"pcode"    : 1012
                },
                {
                     "code"     : 101202
                    ,"value"    : "IN"
                    ,"pcode"    : 1012
                },
                {
                     "code"     : 101203
                    ,"value"    : "OUT"
                    ,"pcode"    : 1012
                },
                {
                     "code"     : 101204
                    ,"value"    : "여의도 연동"
                    ,"pcode"    : 1012
                },
                {
                     "code"     : 101205
                    ,"value"    : "DBS 연동"
                    ,"pcode"    : 1012
                },
                {
                     "code"     : 101206
                    ,"value"    : "SD 집선 스위치"
                    ,"pcode"    : 1012
                }
            ]
        }
        ,PORT_ROLE : {
             "General" : 101301
            ,"In" : 101302
            ,"Out" : 101303
            ,"CasLink" : 101304
            ,"Management" : 101305
            ,"__list__" : [
                {
                     "code"     : 101301
                    ,"value"    : "일반"
                    ,"pcode"    : 1013
                },
                {
                     "code"     : 101302
                    ,"value"    : "IN"
                    ,"pcode"    : 1013
                },
                {
                     "code"     : 101303
                    ,"value"    : "OUT"
                    ,"pcode"    : 1013
                },
                {
                     "code"     : 101304
                    ,"value"    : "CAS 연동"
                    ,"pcode"    : 1013
                },
                {
                     "code"     : 101305
                    ,"value"    : "관리"
                    ,"pcode"    : 1013
                }
            ]
        }
        ,SECURITY_LEVEL : {
             "NoAuthNoPriv" : 101402
            ,"AuthNoPriv" : 101403
            ,"AuthPriv" : 101404
            ,"Undefined" : 101401
            ,"__list__" : [
                {
                     "code"     : 101402
                    ,"value"    : "NoAuthNoPriv"
                    ,"pcode"    : 1014
                },
                {
                     "code"     : 101403
                    ,"value"    : "AuthNoPriv"
                    ,"pcode"    : 1014
                },
                {
                     "code"     : 101404
                    ,"value"    : "AuthPriv"
                    ,"pcode"    : 1014
                },
                {
                     "code"     : 101401
                    ,"value"    : "Undefined"
                    ,"pcode"    : 1014
                }
            ]
        }
        ,AUTH_PROTOCOL : {
             "AuthHMAC128SHA224" : 101501
            ,"AuthHMAC192SHA256" : 101502
            ,"AuthHMAC256SHA384" : 101503
            ,"AuthHMAC384SHA512" : 101504
            ,"AuthMD5" : 101505
            ,"AuthSHA" : 101506
            ,"__list__" : [
                {
                     "code"     : 101501
                    ,"value"    : "AuthHMAC128SHA224"
                    ,"pcode"    : 1015
                },
                {
                     "code"     : 101502
                    ,"value"    : "AuthHMAC192SHA256"
                    ,"pcode"    : 1015
                },
                {
                     "code"     : 101503
                    ,"value"    : "AuthHMAC256SHA384"
                    ,"pcode"    : 1015
                },
                {
                     "code"     : 101504
                    ,"value"    : "AuthHMAC384SHA512"
                    ,"pcode"    : 1015
                },
                {
                     "code"     : 101505
                    ,"value"    : "AuthMD5"
                    ,"pcode"    : 1015
                },
                {
                     "code"     : 101506
                    ,"value"    : "AuthSHA"
                    ,"pcode"    : 1015
                }
            ]
        }
        ,PRIVACY_PROTOCOL : {
             "PrivAES128" : 101602
            ,"PrivAES192" : 101603
            ,"PrivAES256" : 101604
            ,"PrivDES" : 101605
            ,"__list__" : [
                {
                     "code"     : 101602
                    ,"value"    : "PrivAES128"
                    ,"pcode"    : 1016
                },
                {
                     "code"     : 101603
                    ,"value"    : "PrivAES192"
                    ,"pcode"    : 1016
                },
                {
                     "code"     : 101604
                    ,"value"    : "PrivAES256"
                    ,"pcode"    : 1016
                },
                {
                     "code"     : 101605
                    ,"value"    : "PrivDES"
                    ,"pcode"    : 1016
                }
            ]
        }
        ,INST_TYPE : {
             "Service" : 101702
            ,"Encoder" : 101710
            ,"IPAcoMux" : 101711
            ,"MUX" : 101712
            ,"Decoder" : 101713
            ,"L3" : 101714
            ,"Transcoder" : 101715
            ,"Sentry" : 101717
            ,"MSPP" : 101718
            ,"Psi" : 101719
            ,"Multiview" : 101720
            ,"QuetoneExtraction" : 101721
            ,"Thumbnail" : 101722
            ,"VideoStitching" : 101723
            ,"Server" : 101724
            ,"Host" : 101725
            ,"Channel" : 101726
            ,"NmsSystem" : 101727
            ,"Region" : 101728
            ,"AI" : 101729
            ,"Circuit" : 101730
            ,"Verify" : 101731
            ,"Undefined" : 101701
            ,"__list__" : [
                {
                     "code"     : 101702
                    ,"value"    : "서비스"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101710
                    ,"value"    : "Encoder"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101711
                    ,"value"    : "IP-ACO MUX"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101712
                    ,"value"    : "MUX"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101713
                    ,"value"    : "Decoder"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101714
                    ,"value"    : "L3"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101715
                    ,"value"    : "Transcoder"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101717
                    ,"value"    : "Sentry"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101718
                    ,"value"    : "전송 장비"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101719
                    ,"value"    : "PSI"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101720
                    ,"value"    : "Multiview"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101721
                    ,"value"    : "큐톤추출기"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101722
                    ,"value"    : "썸네일"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101723
                    ,"value"    : "비디오스티칭"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101724
                    ,"value"    : "서버"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101725
                    ,"value"    : "호스트"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101726
                    ,"value"    : "채널"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101727
                    ,"value"    : "NMS 시스템"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101728
                    ,"value"    : "지역"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101729
                    ,"value"    : "AI"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101730
                    ,"value"    : "회선"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101731
                    ,"value"    : "Verify"
                    ,"pcode"    : 1017
                },
                {
                     "code"     : 101701
                    ,"value"    : "Undefined"
                    ,"pcode"    : 1017
                }
            ]
        }
        ,EQUIP_CTG : {
             "Broadcasting" : 101801
            ,"Server" : 101802
            ,"NW" : 101803
            ,"Etc" : 101804
            ,"__list__" : [
                {
                     "code"     : 101801
                    ,"value"    : "방송"
                    ,"pcode"    : 1018
                },
                {
                     "code"     : 101802
                    ,"value"    : "서버"
                    ,"pcode"    : 1018
                },
                {
                     "code"     : 101803
                    ,"value"    : "NW"
                    ,"pcode"    : 1018
                },
                {
                     "code"     : 101804
                    ,"value"    : "기타"
                    ,"pcode"    : 1018
                }
            ]
        }
        ,SYS_TYPE : {
             "ChannelTransmission" : 101901
            ,"AdditionalServices" : 101902
            ,"Monitoring" : 101903
            ,"NA" : 101904
            ,"__list__" : [
                {
                     "code"     : 101901
                    ,"value"    : "채널송출"
                    ,"pcode"    : 1019
                },
                {
                     "code"     : 101902
                    ,"value"    : "부가서비스"
                    ,"pcode"    : 1019
                },
                {
                     "code"     : 101903
                    ,"value"    : "모니터링"
                    ,"pcode"    : 1019
                },
                {
                     "code"     : 101904
                    ,"value"    : "N/A"
                    ,"pcode"    : 1019
                }
            ]
        }
        ,OPRTR_NOTE_CTG : {
             "TruePositive" : 102001
            ,"FalsePositive" : 102002
            ,"__list__" : [
                {
                     "code"     : 102001
                    ,"value"    : "정탐"
                    ,"pcode"    : 1020
                },
                {
                     "code"     : 102002
                    ,"value"    : "오탐"
                    ,"pcode"    : 1020
                }
            ]
        }
        ,EVENT_TYPE : {
             "CommunicationsAlarm" : 200101
            ,"QualityOfServiceAlarm" : 200102
            ,"ProcessingErrorAlarm" : 200103
            ,"EquipmentAlarm" : 200104
            ,"EnvironmentalAlarm" : 200105
            ,"QualityOfExperienceAlarm" : 200106
            ,"AIAlarm" : 200107
            ,"Unknown" : 200100
            ,"__list__" : [
                {
                     "code"     : 200101
                    ,"value"    : "Communications alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200102
                    ,"value"    : "Quality of service alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200103
                    ,"value"    : "Processing error alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200104
                    ,"value"    : "Equipment alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200105
                    ,"value"    : "Environmental alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200106
                    ,"value"    : "Quality of experience alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200107
                    ,"value"    : "AI alarm"
                    ,"pcode"    : 2001
                },
                {
                     "code"     : 200100
                    ,"value"    : "알수없음"
                    ,"pcode"    : 2001
                }
            ]
        }
        ,EVENT_STTUS : {
             "Outstanding" : 200201
            ,"Acknowledged" : 200202
            ,"Cleared" : 200203
            ,"Terminated" : 200204
            ,"Purged" : 200205
            ,"__list__" : [
                {
                     "code"     : 200201
                    ,"value"    : "Outstanding"
                    ,"pcode"    : 2002
                },
                {
                     "code"     : 200202
                    ,"value"    : "Acknowledged"
                    ,"pcode"    : 2002
                },
                {
                     "code"     : 200203
                    ,"value"    : "Cleared"
                    ,"pcode"    : 2002
                },
                {
                     "code"     : 200204
                    ,"value"    : "Terminated"
                    ,"pcode"    : 2002
                },
                {
                     "code"     : 200205
                    ,"value"    : "Purged"
                    ,"pcode"    : 2002
                }
            ]
        }
        ,EVENT_CRET_MAGENT : {
             "SameAlarm" : 200301
            ,"RCA" : 200302
            ,"Ai" : 200303
            ,"__list__" : [
                {
                     "code"     : 200301
                    ,"value"    : "동일장비 동일알람"
                    ,"pcode"    : 2003
                },
                {
                     "code"     : 200302
                    ,"value"    : "RCA"
                    ,"pcode"    : 2003
                },
                {
                     "code"     : 200303
                    ,"value"    : "AI"
                    ,"pcode"    : 2003
                }
            ]
        }
        ,EVENT_CORRELATION_TYPE : {
             "RuleRootCause" : 200401
            ,"SameAlarm" : 200402
            ,"AiRootCause" : 200403
            ,"AiAbnormalSign" : 200404
            ,"__list__" : [
                {
                     "code"     : 200401
                    ,"value"    : "Rule Root Cause"
                    ,"pcode"    : 2004
                },
                {
                     "code"     : 200402
                    ,"value"    : "동일알람"
                    ,"pcode"    : 2004
                },
                {
                     "code"     : 200403
                    ,"value"    : "AI Root Cause"
                    ,"pcode"    : 2004
                },
                {
                     "code"     : 200404
                    ,"value"    : "AI 이상징후"
                    ,"pcode"    : 2004
                }
            ]
        }
        ,SVRT : {
             "Critical" : 200603
            ,"Major" : 200604
            ,"Minor" : 200605
            ,"Warning" : 200606
            ,"Indeterminate" : 200602
            ,"Information" : 200607
            ,"Cleared" : 200601
            ,"__list__" : [
                {
                     "code"     : 200603
                    ,"value"    : "Critical"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200604
                    ,"value"    : "Major"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200605
                    ,"value"    : "Minor"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200606
                    ,"value"    : "Warning"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200602
                    ,"value"    : "Indeterminate"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200607
                    ,"value"    : "Information"
                    ,"pcode"    : 2006
                },
                {
                     "code"     : 200601
                    ,"value"    : "Cleared"
                    ,"pcode"    : 2006
                }
            ]
        }
        ,RULE_SET_TYPE : {
             "Snmp" : 200701
            ,"Text" : 200702
            ,"SnmpText" : 200703
            ,"__list__" : [
                {
                     "code"     : 200701
                    ,"value"    : "Snmp 표준"
                    ,"pcode"    : 2007
                },
                {
                     "code"     : 200702
                    ,"value"    : "Text 대상"
                    ,"pcode"    : 2007
                },
                {
                     "code"     : 200703
                    ,"value"    : "Snmp Text"
                    ,"pcode"    : 2007
                }
            ]
        }
        ,LOGICAL_OPERATOR : {
             "And" : 200801
            ,"Or" : 200802
            ,"__list__" : [
                {
                     "code"     : 200801
                    ,"value"    : "AND"
                    ,"pcode"    : 2008
                },
                {
                     "code"     : 200802
                    ,"value"    : "OR"
                    ,"pcode"    : 2008
                }
            ]
        }
        ,ANALS_TYPE : {
             "NA" : 200901
            ,"Regex" : 200902
            ,"Class" : 200903
            ,"__list__" : [
                {
                     "code"     : 200901
                    ,"value"    : "N/A"
                    ,"pcode"    : 2009
                },
                {
                     "code"     : 200902
                    ,"value"    : "정규식"
                    ,"pcode"    : 2009
                },
                {
                     "code"     : 200903
                    ,"value"    : "클래스"
                    ,"pcode"    : 2009
                }
            ]
        }
        ,RELATIONAL_OPERATOR : {
             "Eq" : 201001
            ,"Neq" : 201002
            ,"Gt" : 201003
            ,"Gte" : 201004
            ,"Lt" : 201005
            ,"Lte" : 201006
            ,"Like" : 201007
            ,"NotLike" : 201008
            ,"StartsWith" : 201009
            ,"NotStartWith" : 201010
            ,"EndsWith" : 201011
            ,"NotEndWith" : 201012
            ,"IsNull" : 201013
            ,"IsNotNull" : 201014
            ,"IsEmpty" : 201015
            ,"IsNotEmpty" : 201016
            ,"IsIncluded" : 201017
            ,"IsNotIncluded" : 201018
            ,"__list__" : [
                {
                     "code"     : 201001
                    ,"value"    : "Equal"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201002
                    ,"value"    : "Not equal"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201003
                    ,"value"    : "Greater than"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201004
                    ,"value"    : "Greater than or equal"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201005
                    ,"value"    : "Less than"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201006
                    ,"value"    : "Less than or equal"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201007
                    ,"value"    : "Like"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201008
                    ,"value"    : "Not like"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201009
                    ,"value"    : "Starts with"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201010
                    ,"value"    : "Not start with"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201011
                    ,"value"    : "Ends with"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201012
                    ,"value"    : "Not end with"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201013
                    ,"value"    : "Is null"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201014
                    ,"value"    : "Is not null"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201015
                    ,"value"    : "Is empty"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201016
                    ,"value"    : "Is not empty"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201017
                    ,"value"    : "Is included"
                    ,"pcode"    : 2010
                },
                {
                     "code"     : 201018
                    ,"value"    : "Is not included"
                    ,"pcode"    : 2010
                }
            ]
        }
        ,VOC_TYPE : {
             "Unknown" : 201100
            ,"Voice" : 201101
            ,"VideoQuality" : 201102
            ,"CC" : 201103
            ,"__list__" : [
                {
                     "code"     : 201100
                    ,"value"    : "알수없음"
                    ,"pcode"    : 2011
                },
                {
                     "code"     : 201101
                    ,"value"    : "음성"
                    ,"pcode"    : 2011
                },
                {
                     "code"     : 201102
                    ,"value"    : "화질"
                    ,"pcode"    : 2011
                },
                {
                     "code"     : 201103
                    ,"value"    : "자막"
                    ,"pcode"    : 2011
                }
            ]
        }
        ,EVENT_TRGT_TYPE : {
             "Service" : 201201
            ,"Channel" : 201202
            ,"__list__" : [
                {
                     "code"     : 201201
                    ,"value"    : "서비스"
                    ,"pcode"    : 2012
                },
                {
                     "code"     : 201202
                    ,"value"    : "채널"
                    ,"pcode"    : 2012
                }
            ]
        }
        ,RPA_TMPLT_TYPE : {
             "Cli" : 300101
            ,"Http" : 300102
            ,"Snmp" : 300103
            ,"__list__" : [
                {
                     "code"     : 300101
                    ,"value"    : "Cli"
                    ,"pcode"    : 3001
                },
                {
                     "code"     : 300102
                    ,"value"    : "Http"
                    ,"pcode"    : 3001
                },
                {
                     "code"     : 300103
                    ,"value"    : "Snmp"
                    ,"pcode"    : 3001
                }
            ]
        }
        ,CLI_TYPE : {
             "ShellScript" : 300201
            ,"Cli" : 300202
            ,"__list__" : [
                {
                     "code"     : 300201
                    ,"value"    : "Shell Script"
                    ,"pcode"    : 3002
                },
                {
                     "code"     : 300202
                    ,"value"    : "Cli"
                    ,"pcode"    : 3002
                }
            ]
        }
        ,SNMP_CMD : {
             "GET" : 300301
            ,"SET" : 300302
            ,"WALK" : 300303
            ,"__list__" : [
                {
                     "code"     : 300301
                    ,"value"    : "GET"
                    ,"pcode"    : 3003
                },
                {
                     "code"     : 300302
                    ,"value"    : "SET"
                    ,"pcode"    : 3003
                },
                {
                     "code"     : 300303
                    ,"value"    : "WALK"
                    ,"pcode"    : 3003
                }
            ]
        }
        ,HTTP_METHOD : {
             "GET" : 300401
            ,"POST" : 300402
            ,"PUT" : 300403
            ,"DELETE" : 300404
            ,"__list__" : [
                {
                     "code"     : 300401
                    ,"value"    : "GET"
                    ,"pcode"    : 3004
                },
                {
                     "code"     : 300402
                    ,"value"    : "POST"
                    ,"pcode"    : 3004
                },
                {
                     "code"     : 300403
                    ,"value"    : "PUT"
                    ,"pcode"    : 3004
                },
                {
                     "code"     : 300404
                    ,"value"    : "DELETE"
                    ,"pcode"    : 3004
                }
            ]
        }
        ,PARAM_TYPE : {
             "PathParam" : 300501
            ,"QueryParam" : 300502
            ,"RequestBody" : 300503
            ,"__list__" : [
                {
                     "code"     : 300501
                    ,"value"    : "PathParam"
                    ,"pcode"    : 3005
                },
                {
                     "code"     : 300502
                    ,"value"    : "QueryParam"
                    ,"pcode"    : 3005
                },
                {
                     "code"     : 300503
                    ,"value"    : "RequestBody"
                    ,"pcode"    : 3005
                }
            ]
        }
        ,DATA_TYPE : {
             "String" : 300601
            ,"Number" : 300602
            ,"Boolean" : 300603
            ,"Object" : 300604
            ,"Array" : 300605
            ,"__list__" : [
                {
                     "code"     : 300601
                    ,"value"    : "String"
                    ,"pcode"    : 3006
                },
                {
                     "code"     : 300602
                    ,"value"    : "Number"
                    ,"pcode"    : 3006
                },
                {
                     "code"     : 300603
                    ,"value"    : "Boolean"
                    ,"pcode"    : 3006
                },
                {
                     "code"     : 300604
                    ,"value"    : "Object"
                    ,"pcode"    : 3006
                },
                {
                     "code"     : 300605
                    ,"value"    : "Array"
                    ,"pcode"    : 3006
                }
            ]
        }
        ,WRK_TYPE : {
             "PeriodicInspection" : 300701
            ,"AutoFailover" : 300702
            ,"__list__" : [
                {
                     "code"     : 300701
                    ,"value"    : "정기점검"
                    ,"pcode"    : 3007
                },
                {
                     "code"     : 300702
                    ,"value"    : "자동절체"
                    ,"pcode"    : 3007
                }
            ]
        }
        ,SOP_TYPE : {
             "NetWork" : 400101
            ,"Equipment" : 400102
            ,"Channel" : 400103
            ,"Manual" : 400104
            ,"__list__" : [
                {
                     "code"     : 400101
                    ,"value"    : "네트워크"
                    ,"pcode"    : 4001
                },
                {
                     "code"     : 400102
                    ,"value"    : "방송장비"
                    ,"pcode"    : 4001
                },
                {
                     "code"     : 400103
                    ,"value"    : "채널"
                    ,"pcode"    : 4001
                },
                {
                     "code"     : 400104
                    ,"value"    : "수동입력"
                    ,"pcode"    : 4001
                }
            ]
        }
        ,TOPOL_STTUS : {
             "New" : 500101
            ,"Editing" : 500102
            ,"Completed" : 500103
            ,"Enabled" : 500104
            ,"Disabled" : 500105
            ,"__list__" : [
                {
                     "code"     : 500101
                    ,"value"    : "신규"
                    ,"pcode"    : 5001
                },
                {
                     "code"     : 500102
                    ,"value"    : "수정중"
                    ,"pcode"    : 5001
                },
                {
                     "code"     : 500103
                    ,"value"    : "작성완료"
                    ,"pcode"    : 5001
                },
                {
                     "code"     : 500104
                    ,"value"    : "활성"
                    ,"pcode"    : 5001
                },
                {
                     "code"     : 500105
                    ,"value"    : "비활성"
                    ,"pcode"    : 5001
                }
            ]
        }
        ,TOPOL_TYPE : {
             "Main" : 500201
            ,"General" : 500202
            ,"__list__" : [
                {
                     "code"     : 500201
                    ,"value"    : "메인"
                    ,"pcode"    : 5002
                },
                {
                     "code"     : 500202
                    ,"value"    : "일반"
                    ,"pcode"    : 5002
                }
            ]
        }
        ,CH_WRK_TYPE : {
             "BatchUpload" : 600101
            ,"Create" : 600102
            ,"Update" : 600103
            ,"Delete" : 600104
            ,"DbsSync" : 600105
            ,"__list__" : [
                {
                     "code"     : 600101
                    ,"value"    : "일괄 업로드"
                    ,"pcode"    : 6001
                },
                {
                     "code"     : 600102
                    ,"value"    : "등록"
                    ,"pcode"    : 6001
                },
                {
                     "code"     : 600103
                    ,"value"    : "수정"
                    ,"pcode"    : 6001
                },
                {
                     "code"     : 600104
                    ,"value"    : "삭제"
                    ,"pcode"    : 6001
                },
                {
                     "code"     : 600105
                    ,"value"    : "DBS 동기화"
                    ,"pcode"    : 6001
                }
            ]
        }
        ,CH_WRK_STTUS : {
             "Uploaded" : 600201
            ,"Working" : 600202
            ,"Error" : 600203
            ,"Finished" : 600204
            ,"__list__" : [
                {
                     "code"     : 600201
                    ,"value"    : "업로드"
                    ,"pcode"    : 6002
                },
                {
                     "code"     : 600202
                    ,"value"    : "작업중"
                    ,"pcode"    : 6002
                },
                {
                     "code"     : 600203
                    ,"value"    : "에러"
                    ,"pcode"    : 6002
                },
                {
                     "code"     : 600204
                    ,"value"    : "종료"
                    ,"pcode"    : 6002
                }
            ]
        }
        ,REGLS_STTUS : {
             "Ready" : 600301
            ,"InService" : 600302
            ,"Stop" : 600303
            ,"__list__" : [
                {
                     "code"     : 600301
                    ,"value"    : "준비중"
                    ,"pcode"    : 6003
                },
                {
                     "code"     : 600302
                    ,"value"    : "서비스중"
                    ,"pcode"    : 6003
                },
                {
                     "code"     : 600303
                    ,"value"    : "중지"
                    ,"pcode"    : 6003
                }
            ]
        }
        ,PLTFRM_IND_TYPE : {
             "Kt" : 600401
            ,"SkyLife" : 600402
            ,"HCN" : 600403
            ,"__list__" : [
                {
                     "code"     : 600401
                    ,"value"    : "Kt"
                    ,"pcode"    : 6004
                },
                {
                     "code"     : 600402
                    ,"value"    : "SkyLife"
                    ,"pcode"    : 6004
                },
                {
                     "code"     : 600403
                    ,"value"    : "HCN"
                    ,"pcode"    : 6004
                }
            ]
        }
        ,SORCE_IN_TYPE : {
             "Kt" : 600501
            ,"ARA" : 600502
            ,"__list__" : [
                {
                     "code"     : 600501
                    ,"value"    : "Kt"
                    ,"pcode"    : 6005
                },
                {
                     "code"     : 600502
                    ,"value"    : "ARA"
                    ,"pcode"    : 6005
                }
            ]
        }
        ,CYCL : {
             "Unknown" : 700100
            ,"s10" : 700101
            ,"s30" : 700102
            ,"m1" : 700103
            ,"m3" : 700104
            ,"m5" : 700105
            ,"m10" : 700106
            ,"m20" : 700107
            ,"__list__" : [
                {
                     "code"     : 700100
                    ,"value"    : "알수없음"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700101
                    ,"value"    : "10초"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700102
                    ,"value"    : "30초"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700103
                    ,"value"    : "1분"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700104
                    ,"value"    : "3분"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700105
                    ,"value"    : "5분"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700106
                    ,"value"    : "10분"
                    ,"pcode"    : 7001
                },
                {
                     "code"     : 700107
                    ,"value"    : "20분"
                    ,"pcode"    : 7001
                }
            ]
        }
        ,EXE_STTUS : {
             "Starting" : 700201
            ,"Executing" : 700202
            ,"Completed" : 700203
            ,"Exception" : 700204
            ,"__list__" : [
                {
                     "code"     : 700201
                    ,"value"    : "실행시작"
                    ,"pcode"    : 7002
                },
                {
                     "code"     : 700202
                    ,"value"    : "실행중"
                    ,"pcode"    : 7002
                },
                {
                     "code"     : 700203
                    ,"value"    : "실행완료"
                    ,"pcode"    : 7002
                },
                {
                     "code"     : 700204
                    ,"value"    : "에러발생"
                    ,"pcode"    : 7002
                }
            ]
        }
        ,STAT_TYPE : {
             "Model" : 700304
            ,"Region" : 700301
            ,"Hourly" : 700302
            ,"Daliy" : 700303
            ,"__list__" : [
                {
                     "code"     : 700304
                    ,"value"    : "단말기종별"
                    ,"pcode"    : 7003
                },
                {
                     "code"     : 700301
                    ,"value"    : "지역별"
                    ,"pcode"    : 7003
                },
                {
                     "code"     : 700302
                    ,"value"    : "시간별"
                    ,"pcode"    : 7003
                },
                {
                     "code"     : 700303
                    ,"value"    : "일별"
                    ,"pcode"    : 7003
                }
            ]
        }
        ,STAT_UNIT : {
             "Min5" : 700403
            ,"Min30" : 700404
            ,"Min10" : 700406
            ,"Hour" : 700405
            ,"Day" : 700401
            ,"Week" : 700402
            ,"__list__" : [
                {
                     "code"     : 700403
                    ,"value"    : "5분"
                    ,"pcode"    : 7004
                },
                {
                     "code"     : 700404
                    ,"value"    : "30분"
                    ,"pcode"    : 7004
                },
                {
                     "code"     : 700406
                    ,"value"    : "10분"
                    ,"pcode"    : 7004
                },
                {
                     "code"     : 700405
                    ,"value"    : "시간"
                    ,"pcode"    : 7004
                },
                {
                     "code"     : 700401
                    ,"value"    : "일"
                    ,"pcode"    : 7004
                },
                {
                     "code"     : 700402
                    ,"value"    : "주"
                    ,"pcode"    : 7004
                }
            ]
        }
        ,STB_CTG : {
             "SMONI" : 700501
            ,"GRWV" : 700502
            ,"MCRMO" : 700503
            ,"LENT" : 700504
            ,"NUSE" : 700505
            ,"__list__" : [
                {
                     "code"     : 700501
                    ,"value"    : "소스모니터링"
                    ,"pcode"    : 7005
                },
                {
                     "code"     : 700502
                    ,"value"    : "지상파"
                    ,"pcode"    : 7005
                },
                {
                     "code"     : 700503
                    ,"value"    : "MCR 모니터링"
                    ,"pcode"    : 7005
                },
                {
                     "code"     : 700504
                    ,"value"    : "대여"
                    ,"pcode"    : 7005
                },
                {
                     "code"     : 700505
                    ,"value"    : "미사용"
                    ,"pcode"    : 7005
                }
            ]
        }
        ,STB_LO : {
             "MCRL" : 700601
            ,"MCRR" : 700602
            ,"ORESTO" : 700603
            ,"STBR" : 700604
            ,"FOFFC" : 700605
            ,"MBCTR" : 700606
            ,"__list__" : [
                {
                     "code"     : 700601
                    ,"value"    : "MCR좌측"
                    ,"pcode"    : 7006
                },
                {
                     "code"     : 700602
                    ,"value"    : "MCR우측"
                    ,"pcode"    : 7006
                },
                {
                     "code"     : 700603
                    ,"value"    : "사무실 보관"
                    ,"pcode"    : 7006
                },
                {
                     "code"     : 700604
                    ,"value"    : "STB실"
                    ,"pcode"    : 7006
                },
                {
                     "code"     : 700605
                    ,"value"    : "1층 사무실"
                    ,"pcode"    : 7006
                },
                {
                     "code"     : 700606
                    ,"value"    : "MCR 뒤 중앙"
                    ,"pcode"    : 7006
                }
            ]
        }
        ,RPRT_CYCL : {
             "Day" : 700701
            ,"Week" : 700702
            ,"Month" : 700703
            ,"__list__" : [
                {
                     "code"     : 700701
                    ,"value"    : "일"
                    ,"pcode"    : 7007
                },
                {
                     "code"     : 700702
                    ,"value"    : "주"
                    ,"pcode"    : 7007
                },
                {
                     "code"     : 700703
                    ,"value"    : "월"
                    ,"pcode"    : 7007
                }
            ]
        }
        ,RPRT_TYPE : {
             "Biz" : 700801
            ,"OprtDept" : 700802
            ,"__list__" : [
                {
                     "code"     : 700801
                    ,"value"    : "사업부서용"
                    ,"pcode"    : 7008
                },
                {
                     "code"     : 700802
                    ,"value"    : "운영자용"
                    ,"pcode"    : 7008
                }
            ]
        }
    }